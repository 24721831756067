
























  import Vue from 'vue';
  import Component from 'vue-class-component';
  import { Prop } from 'vue-property-decorator';

  @Component({
    name: 'info',
    components: {},
    mixins: [],
  })
  export default class info extends Vue {
    @Prop({ default: '' }) public logo!: string;
    @Prop({ default: '' }) public name!: string;
    @Prop({ default: '' }) public address!: string;
    @Prop({ default: '' }) public phone!: string;
    @Prop({ default: '' }) public fax!: string;
    @Prop({ default: '' }) public website!: string;

    public created(): void {
      // on componenet created
    }
    public mounted(): void {
      // on componenet mounted
    }
  }
